//TODO: 削除したくないcookie名を入れてください。
var denyDeleteList = [''];

window.cookieconsent.initialise({
    "palette": {
        "popup": {
            "background": "#eaf7f7",
            "text": "#5c7291"
        },
        "button": {
            "background": "#56cbdb",
            "text": "#ffffff"
        }
    },
    "theme": "classic",
    "position": "bottom-right",
    "type": "opt-in",
    "content": {
        "message": "当サイトではCookieを使用しています。Cookieを使用し、トラフィックの分析を行っています。いくつかのCookieは、当サイトを適切に動作させるために必要です。",
        "deny": "最低限のCookieを<br>許可する",
        "allow": "全て許可する",
        "link": "Cookieポリシーについて見る",
        "href": "/privacy/"
    },
    onStatusChange: function (status, chosenBefore) {
        var type = this.options.type;
        var didConsent = this.hasConsented();

        if (type == 'opt-in' && didConsent) {
            // リロードさせてcookieを適応させる
            location.reload();
        }
    },
    // 再度、ポップアップを表示させたとき
    onRevokeChoice: function () {
        var type = this.options.type;
        if (type == 'opt-in') {
            // ドメインありで削除
            deleteCookie('isDomain')
            if (document.cookie.indexOf('=') > -1) {
                // ドメインなしで削除
                deleteCookie()
            }

            //ga
            window['ga-disable-UA-207504866-1'] = true;
        }
    }
});

function deleteCookie(deleteType) {
    var cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim()
        var eqPos = cookie.indexOf('=')
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie

        denyDeleteList.forEach(function(cookieName){
            if (name != cookieName) {
                //NOTE: domainとpath属性がついているcookieに関しては、同じ値を指定してあげて上書き処理しないといけない

                if (deleteType == 'isDomain') {
                    // domain属性が「.phonogram.jp」のやつを削除
                    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.phonogram.jp; path=/;"
                } else {
                    // domain属性が「dump.phonogram.jp」のやつを削除　
                    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;" 
                }
            }
        })
    }
}
