//スライダーカード

$(function(){

  //「.c-sliderCard」があるページだけ実行
  if ($('.c-sliderCard').length) {
    sliderInit();
  }

  //9以下の頭に0を追加
  function addZero(num) {
    if (num < 10) {
      num = String(num);
      num = '0' + num;
    } else {
      num = String(num);
    }
    return num
  }

  //スライダー設定全般
  function sliderInit() {
    let imgSlider = $('.c-sliderCard__img-list');
    let descSlider = $('.c-sliderCard__desc-list');
    let currentPage = $('.c-sliderCard__control-index-num--current');
    let wholePage = $('.c-sliderCard__control-index-num--whole');

    imgSlider.on('init', function(event, slick) {
      currentPage.text(addZero(1));
      wholePage.text(addZero(slick.slideCount));
    });

    //画像スライダー設定
    imgSlider.slick({
      centerMode: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
      slidesToShow: 1,
      arrows: false,
      centerPadding: 0,
      pauseOnHover: false,
      draggable: false,
      asNavFor: '.c-sliderCard__desc-list',
    });

    //本文スライダー
    descSlider.slick({
      centerMode: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
      slidesToShow: 1,
      arrows: false,
      centerPadding: 0,
      pauseOnHover: false,
      draggable: false,
      asNavFor: '.c-sliderCard__img-list',
    });


    //イベント
    descSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      currentPage.text(addZero(nextSlide + 1));
    });
    descSlider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
      $('.c-sliderCard__desc-body').removeClass('is-lineAnimated');
      descSlider.find('.slick-current').find('.c-sliderCard__desc-body').addClass('is-lineAnimated');
    });


    //メソッド
    $('.c-sliderCard__control-arrow--prev').on('click', function(e) {
      e.preventDefault();
      imgSlider.slick('slickPrev');
      imgSlider.slick('slickPlay');
    });
    $('.c-sliderCard__control-arrow--next').on('click', function(e) {
      e.preventDefault();
      imgSlider.slick('slickNext');
      imgSlider.slick('slickPlay');
    });
  }

});